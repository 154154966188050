<script lang="ts">
	import WistiaPopup from './wistia-popup.svelte'

	export let section: any

	function extract_id(url: string): string | null {
		// Find the index of '/medias/'
		const mediaIndex = url.indexOf('/medias/')
		if (mediaIndex === -1) {
			// '/medias/' not found in the URL
			return null
		}

		// Extract the part after '/medias/'
		const id_start_index = mediaIndex + '/medias/'.length
		const id_and_beyond = url.slice(id_start_index)

		// Find the next '/' or '?' after the ID, if any
		const endIndex = id_and_beyond.search(/[\/?]/)
		if (endIndex !== -1) {
			// Return the substring from the start to the found index
			return id_and_beyond.slice(0, endIndex)
		}

		// If there's no additional '/' or '?' after the ID
		return id_and_beyond
	}

	$: ({ url, video_columns, quote, name, details, company, byline, backlink, logo } =
		section?.testimonial || {})
	$: id = extract_id(url)
</script>

<div class="relative z-20 bg-white pt-16 lg:py-0">
	<div class="bg-gray-800 pb-16 lg:relative lg:z-10 lg:pb-0 lg:pl-8 2xl:pl-0">
		<div aria-hidden="true" class="absolute inset-x-0 top-0 h-1/4 bg-white sm:h-1/2 lg:hidden" />
		<div
			class="mx-auto max-w-3xl px-4 sm:px-6 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-12 lg:place-items-stretch lg:gap-8 lg:px-8 2xl:gap-16"
		>
			<div
				class="{video_columns} relative place-content-stretch overflow-hidden rounded-lg shadow-xl lg:-m-8 lg:grid"
			>
				{#if id}
					<WistiaPopup {id} />
				{/if}
			</div>
			<div class="quote-columns {video_columns} mt-12 lg:m-0 lg:pl-8">
				<div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
					<h2 class="mb-8 flex gap-4 text-3xl font-semibold text-gray-100">
						{#if byline}
							{byline}{/if}
					</h2>
					<blockquote>
						<div>
							<svg
								class="h-12 w-12 text-white opacity-25"
								fill="currentColor"
								viewBox="0 0 32 32"
								aria-hidden="true"
							>
								<path
									d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"
								/>
							</svg>
							<p class="mt-6 text-xl font-semibold text-white">
								{quote}
							</p>
						</div>
						<footer class="mt-6">
							<p class="font-medium text-gray-300">
								{name}{#if details}, {details}{/if}<br />
								<span class="text-gray-400">
									{#if backlink && !logo}
										<a href={backlink} class="underline" target="_blank">
											{company}
										</a>
									{:else}
										{company}
									{/if}
								</span>
							</p>
							<p class="font-medium text-gray-400">
								{#if logo}
									<div class="mt-6 flex">
										{#if backlink}
											<a
												href={backlink}
												class="flex text-gray-400 hover:text-gray-300"
												target="_blank"
											>
												{@html logo}
											</a>
										{:else}
											{@html logo}
										{/if}
									</div>
								{/if}
							</p>
						</footer>
					</blockquote>
				</div>
			</div>
		</div>
	</div>
</div>

<style lang="postcss">
	.video-3 {
		@apply lg:col-span-3;
	}
	.video-4 {
		@apply lg:col-span-4;
	}
	.video-5 {
		@apply lg:col-span-5;
	}
	.video-6 {
		@apply lg:col-span-6;
	}
	.video-7 {
		@apply lg:col-span-7;
	}
	.video-8 {
		@apply lg:col-span-8;
	}
	.quote-columns.video-3 {
		@apply lg:col-span-9;
	}
	.quote-columns.video-4 {
		@apply lg:col-span-8;
	}
	.quote-columns.video-5 {
		@apply lg:col-span-7;
	}
	.quote-columns.video-6 {
		@apply lg:col-span-6;
	}
	.quote-columns.video-7 {
		@apply lg:col-span-5;
	}
	.quote-columns.video-8 {
		@apply lg:col-span-4;
	}
</style>
