<script lang="ts">
	import { StructuredText } from '@datocms/svelte'
	import { Button, Paragraph } from '$lib/components'
	import { isParagraph } from 'datocms-structured-text-utils'

	export let section: any

	$: ({ title, advantages } = section || {})
</script>

<section class="advantages relative grid place-items-center bg-white pb-20 pt-16">
	<div class="overflow-hidden">
		<div class="relative w-full max-w-[100rem] px-8 lg:px-20 lg:py-12">
			<div class="relative md:grid md:grid-cols-2 lg:ml-6 lg:grid-cols-3 lg:gap-x-12">
				<div class="col-span-2 lg:col-span-1">
					<h2
						class="text-center text-4xl font-extrabold tracking-tight text-gray-800 sm:text-5xl lg:text-left"
					>
						{title}
					</h2>
				</div>
				<div
					class="col-span-2 mt-10 space-y-10 sm:grid sm:grid-cols-2 sm:gap-10 sm:space-y-0 lg:mt-0"
				>
					{#each advantages as advantage}
						{#if advantage._modelApiKey === 'list_point'}
							<div
								class="grid grid-rows-[3.5rem_auto_1fr] place-items-center gap-8 md:place-items-start"
							>
								<div class="grid h-12 w-12 place-items-center rounded-md bg-gray-200 text-white">
									{#if advantage.svg_icon_reference}
										{@html advantage.svg_icon_reference.versions.find(
											(v) => v.version === 'outline',
										).tag}
									{:else}
										{@html advantage.svg_icon}
									{/if}
								</div>
								<h4
									class="text-center text-2xl font-extrabold text-gray-900 sm:text-2xl md:text-left"
								>
									{advantage.title}
								</h4>
								<div class="">
									<div class="text-base text-gray-600">
										<StructuredText data={advantage.body} components={[[isParagraph, Paragraph]]}
										></StructuredText>
									</div>
								</div>
							</div>
						{/if}
					{/each}
				</div>
				{#each advantages as advantage}
					{#if advantage._modelApiKey === 'cta_button'}
						<div class="col-span-1 mt-12 flex justify-center sm:justify-start lg:col-start-2">
							<Button class="cta-button {advantage.primary ? 'primary' : 'secondary'}"
								>{advantage.label}</Button
							>
						</div>
					{/if}
				{/each}
			</div>
		</div>
	</div>
</section>

<style lang="postcss">
	.advantages :global(p) {
		@apply my-3;
	}
	.advantages :global(p:first-of-type) {
		@apply mt-0;
	}
</style>
