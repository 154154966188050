<script lang="ts">
	export let section: any

	$: ({ name, details, quote, image } = section?.testimonial || {})
	$: ({ layout } = section || {})
	// TODO: add layout without image
</script>

<div class="relative z-20 bg-white pt-16 lg:py-0">
	<div class="bg-gray-800 pb-16 lg:relative lg:z-10 lg:pb-0">
		<div class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8">
			<div class="relative lg:-my-8">
				<div aria-hidden="true" class="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden" />
				<div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:h-full lg:p-0">
					<div
						class="aspect-h-6 aspect-w-10 overflow-hidden rounded-md shadow-xl sm:aspect-h-7 sm:aspect-w-16 lg:h-full"
					>
						<picture class="flex w-full">
							<source media="(max-width: 1023px)" srcset={image.landscape.src} type="image/webp" />
							<source media="(min-width: 1024px)" srcset={image.portrait.src} type="image/webp" />
							<img
								src={image.landscapeFallback.src}
								alt={image.alt || 'Kundenportrait'}
								class="w-full object-cover object-top lg:h-full lg:w-full"
							/>
						</picture>
					</div>
				</div>
			</div>
			<div class="mt-12 lg:col-span-2 lg:m-0 lg:pl-8">
				<div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
					<blockquote>
						<div>
							<svg
								class="h-12 w-12 text-white opacity-25"
								fill="currentColor"
								viewBox="0 0 32 32"
								aria-hidden="true"
							>
								<path
									d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"
								/>
							</svg>
							<p class="mt-6 text-2xl font-semibold text-white">
								{quote}
							</p>
						</div>
						<footer class="mt-6">
							<p class="font-medium text-gray-100">Kundenfeedback</p>
							<p class="font-medium text-gray-400">
								{#if layout !== 'anonymous'}{name}{#if details}, {details}{/if}{/if}
							</p>
						</footer>
					</blockquote>
				</div>
			</div>
		</div>
	</div>
</div>
