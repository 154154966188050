<script lang="ts">
	import Stars from '../ui/stars.svelte'

	export let section: any
	let stars_section: HTMLElement,
		cards_section: HTMLElement,
		y = 0,
		vh = 0,
		vw = 0,
		stars_into_view = false,
		cards_into_view = false,
		stars_colored = false

	$: ({ header, stars, testimonials } = section || {})
	$: infinite_testimonials = [
		...testimonials,
		...testimonials,
		...testimonials,
		...testimonials,
		...testimonials,
		...testimonials,
		...testimonials,
		...testimonials,
		...testimonials,
	]
	$: if (stars_into_view) stars_colored = true // Set stars to colored when in view and does not reset
</script>

<svelte:window
	bind:scrollY={y}
	bind:innerHeight={vh}
	bind:innerWidth={vw}
	on:scroll={() => {
		stars_into_view =
			vh > stars_section.getBoundingClientRect().top &&
			stars_section.getBoundingClientRect().bottom > 0
		cards_into_view =
			vh > cards_section.getBoundingClientRect().top &&
			cards_section.getBoundingClientRect().bottom > 0
	}}
/>

<section id="stars" class="stars relative grid justify-center gap-10 bg-white pb-12 pt-20">
	<div class="grid w-full place-items-center gap-8 px-8">
		<div class="grid w-full max-w-5xl text-center">
			{@html header}
		</div>
	</div>

	<div class="flex justify-center" bind:this={stars_section}>
		<div class="relative h-[5.6875rem] w-[75vw] sm:w-[50vw]">
			<img src="/i/stars_gray.svg" alt="Gray Star" class="big-stars gray-star" />
			<img
				src="/i/stars_orange_47.svg"
				alt="Colored Star"
				class="big-stars"
				class:colored-stars={stars_colored}
			/>
		</div>
	</div>

	<div class="w-full overflow-hidden">
		<div
			class="animate-scroll grid w-screen auto-cols-max grid-flow-col gap-6"
			bind:this={cards_section}
			class:pause-scroll={!cards_into_view}
		>
			{#each infinite_testimonials as { quote, name, stars, details, logo, backlink }}
				<div class="my-6 w-96 rounded-lg border border-gray-100 bg-gray-50 shadow-lg">
					<blockquote class="grid px-4 py-5 text-sm sm:p-6">
						<div class="mb-4"><Stars size="h-4 w-4" color="text-yellow-300" /></div>
						<div class="border-l-4 border-gray-200 pl-4 font-normal text-gray-800">
							{quote}
						</div>
						<footer class="mt-4 font-normal text-gray-500">
							{name}<span class=" text-gray-400"
								>{#if details}, {details}{/if}</span
							>
						</footer>
					</blockquote>
				</div>
			{/each}
		</div>
	</div>
</section>

<style lang="postcss">
	@keyframes scroll {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(-2000%); /* Adjust animation speed here */
		}
	}

	/* Slow down animation for larger screens */
	@media (min-width: 641px) {
		@keyframes scroll {
			0% {
				transform: translateX(0);
			}
			100% {
				transform: translateX(-1000%);
			}
		}
	}

	@media (min-width: 769px) {
		@keyframes scroll {
			0% {
				transform: translateX(0);
			}
			100% {
				transform: translateX(-500%);
			}
		}
	}

	.stars :global(h2) {
		@apply mb-8 text-4xl font-extrabold tracking-tight text-gray-800 sm:text-5xl;
	}

	.stars :global(p) {
		@apply max-w-[68ch] place-self-center text-xl font-medium tracking-tight text-gray-400 sm:mt-4;
	}

	/* Let cards scroll automatically */
	.animate-scroll {
		animation: scroll 120s linear infinite;
	}

	/* Pause scrolling when the cards are out of view */
	.pause-scroll {
		animation-play-state: paused;
	}

	/* Star animation */
	@keyframes fill_stars {
		from {
			clip-path: inset(0 100% 0 0);
		}
		to {
			clip-path: inset(0 0 0 0);
		}
	}

	/* .star-container {
		@apply relative;
		width: 35rem;
		height: 5.6875rem;
	} */

	.big-stars {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.colored-stars {
		clip-path: inset(0 100% 0 0);
		transition: clip-path 3s ease;
		animation: fill_stars 2s linear forwards;
	}
</style>
