<script lang="ts">
	export let section: any

	$: ({ title, subtitle, label1, label2, label3, number1, number2, number3 } = section || {})
</script>

<section id="stats" class="relative">
	<div class="border-b-2 border-gray-100 bg-white">
		<div class="mx-auto max-w-7xl px-4 pb-12 pt-16 sm:px-6 sm:pt-20 lg:px-8 lg:pb-24 lg:pt-20">
			<div class="mx-auto max-w-4xl text-center">
				<slot name="title">
					<h2 class="mb-8 text-4xl font-extrabold tracking-tight text-gray-800 sm:text-5xl">
						{title}
					</h2>
					<p class="mt-6 text-xl font-medium tracking-tight text-gray-400 sm:mt-4">
						{subtitle}
					</p>
				</slot>
			</div>
			<div class="mt-10 text-center sm:mx-auto sm:grid sm:max-w-3xl sm:grid-cols-3 sm:gap-8">
				<slot name="stats">
					<div class="flex flex-col">
						<div class="order-2 mt-2 text-lg font-medium leading-6 tracking-tight text-gray-400">
							{label1}
						</div>
						<div class="order-1 text-5xl font-extrabold text-gray-900 sm:tracking-tight">
							{number1}
						</div>
					</div>
					<div class="mt-10 flex flex-col sm:mt-0">
						<div class="order-2 mt-2 text-lg font-medium leading-6 tracking-tight text-gray-400">
							{label2}
						</div>
						<div class="order-1 text-5xl font-extrabold text-gray-900 sm:tracking-tight">
							{number2}
						</div>
					</div>
					<div class="mt-10 flex flex-col sm:mt-0">
						<div class="order-2 mt-2 text-lg font-medium leading-6 tracking-tight text-gray-400">
							{label3}
						</div>
						<div class="order-1 text-5xl font-extrabold text-gray-900 sm:tracking-tight">
							{number3}
						</div>
					</div>
				</slot>
			</div>
		</div>
	</div>
</section>
