<script lang="ts">
	export let id: string
</script>

<svelte:head>
	<script src="https://fast.wistia.com/embed/medias/{id}.jsonp" async></script>
	<script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
</svelte:head>

<div class="wistia_responsive_padding lg:-m-8" style="padding: 56.25% 0 0 0; position: relative">
	<div
		class="wistia_responsive_wrapper"
		style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
	>
		<span
			class="wistia_embed wistia_async_{id} popover=true videoFoam=true"
			style="
        display: inline-block;
        height: 100%;
        position: relative;
        width: 100%;
      "><span class="hidden">&nbsp;</span></span
		>
	</div>
</div>
