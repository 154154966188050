<script lang="ts">
	export let text_size: 'large' | 'regular' | 'small' = 'regular'
</script>

{#if text_size === 'large'}
	<svelte:element this={'p'} class="text-lg sm:text-xl"><slot /></svelte:element>
{:else if text_size === 'small'}
	<svelte:element this={'p'} class="text-sm sm:text-base"><slot /></svelte:element>
{:else}
	<svelte:element this={'p'} class="text-lg"><slot /></svelte:element>
{/if}

<!-- <svelte:element this={'p'}><slot /></svelte:element> -->

<style lang="postcss">
	p {
		@apply mt-6 leading-snug text-gray-700 sm:leading-normal;
	}
</style>
